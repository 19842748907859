.overlay {
  position: absolute;
  flex: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 50%;
  background-color: rgba(240, 240, 240, 0);
  transition-property: background-color;
  transition-duration: 0.4s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0, 0.74, 0.47, 0.47);
}

.overlay:active {
  background-color: rgba(150, 150, 150, 0.2);
}
