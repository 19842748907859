.refresher {
  position: absolute;
  width: 26px;
  height: 26px;
  left: 50%;
  will-change: transform, opacity;
  display: flex;
  background: #fff;
  visibility: hidden;
  z-index: 1000;
  border-radius: 50%;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 2px 1px;
  transform: translate(-50%);
  top: 20px;
  transition: 0.1s;
}

.refreshing {
  z-index: 1;
  visibility: visible;
}

.replay {
  transition: 0.1s;
}

.spinner div {
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  border: 3px solid #fff;
  border-radius: 50%;
  animation: spinner 0.8s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #099e44 transparent transparent transparent;
}

.spinner div:nth-child(1) {
  animation-delay: -0.3s;
}

.spinner div:nth-child(2) {
  animation-delay: -0.2s;
}

.spinner div:nth-child(3) {
  animation-delay: -0.1s;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
