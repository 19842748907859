.circle-ripple {
  border: 2px solid #e23644;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  animation: ripple 1s linear infinite;
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(229, 115, 115, 0.3),
      0 0 0 0.4em rgba(229, 115, 115, 0.3);
  }
  100% {
    box-shadow: 0 0 0 0.4em rgba(229, 115, 115, 0.3),
      0 0 0 0.4em rgba(229, 115, 115, 0);
  }
}
